<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title> Mapping Code </v-card-title>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="mappingCode"
          :custom-filter="searchByUsername"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-btn
                color="primary"
                dark
                class="mb-1"
                @click="createMappingCode()"
              >
                New Mapping Code
              </v-btn>
              <v-btn
                color="success"
                dark
                class="mb-1 ml-2"
              >
                <download-csv :data="mappingCode">
                  Download Mapping Code
                </download-csv>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                :append-icon="icons.mdiMagnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editMappingCode(item.id)"
            >
              {{ icons.mdiPencil }}
            </v-icon>
            <v-icon
              small
              @click="triggerConfirmDialog(item.id)"
              @click.stop="dialog = true"
            >
              {{ icons.mdiDelete }}
            </v-icon>
          </template>
        </v-data-table>
        <v-dialog
          v-model="dialog"
          max-width="290"
        >
          <v-card>
            <v-card-title class="text-h5">
              Confirm Delete
            </v-card-title>

            <v-card-text> Are you sure want to delete this Mapping Code? </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="resetDialogState()"
              >
                No
              </v-btn>

              <v-btn
                color="green darken-1"
                text
                @click="deleteMappingCodeConfirm()"
              >
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiPencil, mdiDelete, mdiMagnify } from '@mdi/js'
import JsonCSV from 'vue-json-csv'
import MappingCodeService from '../../services/MappingCodeService'

export default {
  components: {
    downloadCsv: JsonCSV,
  },
  data() {
    return {
      search: '',
      itemDialogId: -1,
      dialog: false,
      icons: {
        mdiPencil,
        mdiDelete,
        mdiMagnify,
      },
      mappingCode: [],
      title: '',
      headers: [
        {
          text: 'Username',
          align: 'start',
          value: 'username',
          sortable: false,
        },
        {
          text: 'Client Code Elexys',
          value: 'clientCode',
          sortable: false,
        },
        {
          text: 'Client Code Genesis',
          value: 'clientCodeGenesis',
          sortable: false,
        },
        {
          text: 'Company',
          value: 'company',
          sortable: false,
        },
        {
          text: 'City',
          value: 'city',
          sortable: false,
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    }
  },
  mounted() {
    this.retrieveMappingCodes()
  },
  methods: {
    retrieveMappingCodes() {
      MappingCodeService.getAll()
        .then(response => {
          const { data } = response.data
          this.mappingCode = data.map(this.getDisplayMappingCode)
        })
        .catch(e => {
          console.log(e)
        })
    },

    refreshList() {
      this.retrieveMappingCodes()
    },

    triggerConfirmDialog(id) {
      this.itemDialogId = id
      this.dialog = true
    },

    resetDialogState() {
      this.itemDialogId = -1
      this.dialog = false
    },

    deleteMappingCodeConfirm() {
      this.deleteMappingCode(this.itemDialogId)
      this.resetDialogState()
    },

    editMappingCode(id) {
      this.$router.push({ name: 'mapping-code-details', params: { id } })
    },

    createMappingCode() {
      this.$router.push({ name: 'mapping-code-create' })
    },

    deleteMappingCode(id) {
      MappingCodeService.delete(id)
        .then(() => {
          this.refreshList()
        })
        .catch(e => {
          console.log(e)
        })
    },

    getDisplayMappingCode(mappingToken) {
      return {
        id: mappingToken.id,
        username: mappingToken.username,
        clientCode: mappingToken.clientCode,
        clientCodeGenesis: mappingToken.clientCodeGenesis,
        company: mappingToken.company,
        city: mappingToken.city,
      }
    },

    searchByUsername(value, search) {
      return (
        value != null
        && search != null
        && typeof value === 'string'
        && value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      )
    },
  },
}
</script>
