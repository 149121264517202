import http from '../http-common'

class MappingCodeService {
  getAll = () => http.get('/mapping-code')

  get = id => http.get(`/mapping-code/${id}`)

  create = data => http.post('/mapping-code', data)

  update = (id, data) => http.put(`/mapping-code/${id}`, data)

  delete = id => http.delete(`/mapping-code/${id}`)
}

export default new MappingCodeService()
